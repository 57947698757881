<template>
<div class="other-box">
  <component :is="isComponent" :businessData="businessData"></component>
</div>
</template>
<script>
export default {
  name: "other",
  data: () => ({
    isComponent: null,
    businessData: {
      name: "testtest",
      age: "12"
    }
  }),
  methods: {
   // 按需注册第三方组件
   registerComponents() {
      if (window.otherPageList) {
         const registerComponent = window.otherPageList.find((item) => item.name === this.$route.query.name);
         if (registerComponent) {
          const script = document.createElement("script");
          script.src = registerComponent.url;
            script.onload = () => {
              this.isComponent = window[registerComponent.name].default;
            };
            document.body.appendChild(script);
          }
         }
    }
  },
  mounted() {
    this.registerComponents();
  }
};
</script>
<style scoped>
  .other-box{
    width: 100%;
    height: 100%;
  }
</style>
